import React, { useMemo, useRef, useState } from 'react'
import PageConfig from '@/component/layout/PageConfig.tsx'
import DashboardMap from '@/pages/dashboard/DashboardMapDefault.tsx'
import AlertBio from '@/pages/dashboard/UrgentNotification.tsx'
import { isNotEmptyArray } from '@/util/array.ts'
import useQueryGetUsers, { AlertStatus, DeviceStatus } from '@/api/user/getUsers.ts'
import useQueryGetMe, { DashboardType } from '@/api/auth/getMe.ts'
import useQueryGetAlertBioLive from '@/api/notification/getAlertBioLive.ts'
import BioAlertDetailModal from '@/pages/notification/bio/BioAlertModal.tsx'
import useQueryGetMyPermission, { PermissionAction, PermissionSubject } from '@/api/auth/getPermission.ts'
import AlertStatusBadge from '@/component/badge/AlertStatusBadge.tsx'
import useQueryGetEmergencyAlerts from '@/api/dashboard/getEmergencyAlerts.ts'
import useQueryGetBioAlerts from '@/api/dashboard/getBioAlerts.ts'
import DeviceStatusBadge from '@/component/badge/DeviceStatusBadge.tsx'
import DashboardMapForCareManager from '@/pages/dashboard/DashboardMapForCareManager.tsx'
import { useTranslation } from 'react-i18next'

type IProps = {}

const Dashboard: React.FC<IProps> = ({}) => {
    const { t } = useTranslation()
    const { hasPermission } = useQueryGetMyPermission()
    const {
        data: bioAlertRes,
        refetch: refetchBioAlerts
    } = useQueryGetAlertBioLive(hasPermission(PermissionSubject.ALERT_BIO, PermissionAction.READ))
    const { data: users } = useQueryGetUsers()
    const { data: me } = useQueryGetMe()
    const { data: emergencyAlerts } = useQueryGetEmergencyAlerts()
    const { data: bioAlerts } = useQueryGetBioAlerts()
    const mapRef = useRef<any>()

    const [detailModalId, setDetailModalId] = useState<number>(-1)
    const detailModalRef = useRef<any>()

    const markers = useMemo(() => {
        return users?.filter(item => item?.device?.status)
    }, [users])

    const isCareManager = useMemo(
        () => me?.dashboardType == DashboardType.CARE_MANAGER,
        [me?.dashboardType]
    )

    return <>
        <PageConfig showTopNav={true} showSideNav={true} className={'min-w-[800px]'}>
            <div className={'relative h-full'}>
                {!isCareManager ? <DashboardMap className={'h-full w-full'} ref={mapRef} /> :
                    <DashboardMapForCareManager className={'h-full w-full'} ref={mapRef} />}

                <div
                    className={'w-full flex h-full justify-between overflow-hidden absolute top-0 pointer-events-none'}>
                    <div className={'flex gap-[15px] justify-between items-start pl-[15px] pt-[15px]'}
                         style={{ width: '100%' }}>

                        {isCareManager ? <div
                            className={'bg-white px-[18px] py-[21px] border border-solid border-gray2 flex flex-col w-[200px] font-medium gap-[8px]'}>
                            <p className={'text-center px-[10px] py-[8px]'}>전체 회원
                                / {users?.length}명</p>
                            <div className={'w-full h-[1px] bg-gray2 my-[8px]'} />
                            <div className={'flex flex-col gap-[8px]'}>
                                <div className="flex items-center gap-[5px]" style={{ lineHeight: '1' }}> {/* Flex로 수직 정렬 */}
                                    <DeviceStatusBadge value={DeviceStatus.EMERGENCY} className="icon" />
                                    <p>긴급알림 / {markers?.filter(item => item?.device?.status == DeviceStatus.EMERGENCY)?.length || 0}명</p>
                                </div>
                                <div className="flex items-center gap-[5px]" style={{ lineHeight: '1' }}>
                                    <DeviceStatusBadge value={DeviceStatus.ONLINE} className="icon" />
                                    <p>사용중 / {markers?.filter(item => item?.device?.status == DeviceStatus.ONLINE)?.length || 0}명</p>
                                </div>
                                <div className="flex items-center gap-[5px]" style={{ lineHeight: '1' }}>
                                    <DeviceStatusBadge value={DeviceStatus.CHARGING} className="icon" />
                                    <p>충전중 / {markers?.filter(item => item?.device?.status == DeviceStatus.CHARGING)?.length || 0}명</p>
                                </div>
                                <div className="flex items-center gap-[5px]" style={{ lineHeight: '1' }}>
                                    <DeviceStatusBadge value={DeviceStatus.OFFLINE} className="icon" />
                                    <p>꺼짐 / {markers?.filter(item => item?.device?.status == DeviceStatus.OFFLINE)?.length || 0}명</p>
                                </div>
                            </div>
                            {me && me?.latitude && me?.longitude &&
                                <button className={'pointer-events-auto py-[8.5px] text-white bg-main_b'}
                                        onClick={() => {
                                            mapRef?.current?.setLocation(me.latitude, me.longitude, 18)
                                        }}>센터 위치 보기</button>}
                        </div> : <div
                            className={'bg-white px-[18px] py-[21px] border border-solid border-gray2 flex flex-col font-medium gap-[8px]'}>
                            <p className={'text-center px-[10px] py-[8px]'}>{t('dashboard.summary.title', { count: users?.length })}</p>
                            <div className={'w-full h-[1px] bg-gray2 my-[8px]'} />
                            <div className="flex items-center gap-[5px]" style={{ lineHeight: '1' }}> {/* Flex로 수직 정렬 */}
                                <AlertStatusBadge value={AlertStatus.EMERGENCY} className="icon" />
                                <p>{t('dashboard.summary.emergency', { count: emergencyAlerts?.data?.length || 0 })}</p>
                            </div>
                            <div className="flex items-center gap-[5px]" style={{ lineHeight: '1' }}>
                                <AlertStatusBadge value={AlertStatus.BIO} className="icon" />
                                <p>{t('dashboard.summary.bio', { count: bioAlerts?.data?.length || 0 })}</p>
                            </div>
                            {me && me?.latitude && me?.longitude &&
                                <button className={'pointer-events-auto py-[8.5px] text-white bg-main_b'}
                                        onClick={() => {
                                            mapRef?.current?.setLocation(me.latitude, me.longitude, 18)
                                        }}>{t('dashboard.summary.findCenterLocation')}</button>}
                        </div>}

                    </div>
                    <div
                        className={'flex flex-col overflow-y-auto items-end'}
                        style={{ position: 'relative', width: '100%' }}>
                        <div className={'flex flex-col pointer-events-auto pr-[15px] pt-[15px]'}>
                            {hasPermission(PermissionSubject.ALERT_BIO, PermissionAction.READ) && bioAlertRes && isNotEmptyArray(bioAlertRes.bios) && bioAlertRes.bios?.map(item =>
                                <AlertBio key={item?.id}
                                          className={'shadow-lg mb-[10px]'}  {...item}
                                          onRefreshList={refetchBioAlerts}
                                          onClickMarker={(id) => {
                                              const user = users?.find(user => user?.id == id)
                                              mapRef.current?.setLocation(user?.location?.latitude, user?.location?.longitude, 18)
                                          }}
                                          onClickItem={id => {
                                              setDetailModalId(id)
                                              detailModalRef.current?.show()
                                          }}
                                />)}
                        </div>
                    </div>
                </div>
            </div>
        </PageConfig>
        <BioAlertDetailModal ref={detailModalRef} defaultId={detailModalId} refetchAlerts={refetchBioAlerts} />
    </>
}
export default Dashboard